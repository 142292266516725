import { render, staticRenderFns } from "./HomeStats.vue?vue&type=template&id=7741bccc&scoped=true&"
import script from "./HomeStats.vue?vue&type=script&lang=js&"
export * from "./HomeStats.vue?vue&type=script&lang=js&"
import style0 from "./HomeStats.vue?vue&type=style&index=0&id=7741bccc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7741bccc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlexibleBlockTitle: require('/src/components/ContentBlocks/FlexibleBlockTitle.vue').default,MatchCentreTabs: require('/src/components/MatchCentre/MatchCentreTabs.vue').default,PlayersBlock: require('/src/components/Stats/Blocks/PlayersBlock.vue').default,LeaguePositions: require('/src/components/Stats/Charts/Custom/LeaguePositions.vue').default,TeamsBlock: require('/src/components/Stats/Blocks/TeamsBlock.vue').default,GoalPositions: require('/src/components/Stats/Charts/Custom/GoalPositions.vue').default,LeagueTableLarge: require('/src/components/MatchComponents/LeagueTableLarge.vue').default,DoughnutChart: require('/src/components/Stats/Charts/DoughnutChart.vue').default,DoughnutChartVsLeagueAverage: require('/src/components/Stats/Charts/Custom/DoughnutChartVsLeagueAverage.vue').default,PolarChart: require('/src/components/Stats/Charts/PolarChart.vue').default,ShotPlacementChart: require('/src/components/Stats/Charts/Custom/ShotPlacementChart.vue').default})
