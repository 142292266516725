
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      options: {
        title: {},
        series: [],
        autoresize: true,
        responsive: true,
        maintainAspectRatio: true,
        xAxis: {
          name: `Matchday`,
          nameLocation: `middle`,
          nameGap: 40,
          data: [],
          axisLabel: {
            show: true,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: `Position`,
          nameLocation: `middle`,
          nameGap: 20,
          inverse: true,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        tooltip: {
          formatter: params => {
            const pos = this.getOrdinal(params.value);
            const fixture = this.getFixture(parseInt(params.name));
            const team = fixture.teams.team;
            const otherTeam = fixture.teams.otherTeam;
            return `
              <h4 class="m-0">${pos}</h4>
              <p>${team.displayName} ${team.score} - ${otherTeam.score} ${otherTeam.displayName}</p>
              <p class="ts-h6">Matchday ${params.name}</p>
            `;
          },
        },
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      handler () {
        this.getData();
      },
    },
  },
  methods: {
    getOrdinal (num) {
      const number = parseInt(num);
      return `${num}${
        [`st`, `nd`, `rd`][((((number + 90) % 100) - 10) % 10) - 1] || `th`
      }`;
    },
    getData () {
      const tableData = this.data.positions;

      if (!tableData) return;

      const xData = [];

      for (let i = 0; i < tableData.length; i++) {
        xData.push(i + 1);
      }

      this.options.series = [
        {
          type: `line`,
          data: tableData,
          smooth: true,
          symbol: `circle`,
          symbolSize: 10,
          lineStyle: {
            color: `#B5050F`,
            width: 3,
            shadowColor: `rgba(0,0,0,0.3)`,
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          itemStyle: {
            color: `#B5050F`,
          },
        },
      ];

      this.options.xAxis.data = xData;
    },
    getFixture (matchdayNumber) {
      return (
        this.data.matchdays.find(
          matchday => matchday.matchday === matchdayNumber,
        ) ?? null
      );
    },
  },
};
