
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      windowWidth: 0,
      sponsorPost: null,
    };
  },
  computed: {
    colours () {
      if (this.windowWidth) {
        return this.windowWidth < 768
          ? [`bg-grey500`, `c-grey100`]
          : [this.block.background, this.getTextColour(this.block.background)];
      }
      return false;
    },
    lineClass () {
      return this.block.background === `bg-grey100` ? `has-line` : ``;
    },
    sponsor () {
      return this.block.sponsor;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.getSponsorPost();
      this.windowWidth = window.innerWidth;
      window.addEventListener(`resize`, () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },
  methods: {
    async getSponsorPost () {
      if (this.sponsor?.sponsor) {
        const params = {
          fields: `id,title,acf,image`,
          posts: {
            per_page: 1,
            p: this.sponsor.sponsor.ID,
            post_type: `partner`,
          },
        };

        const request = await this.$getPosts(`/posts`, params);
        if (request !== null) {
          this.sponsorPost = request[0];
        }
      }
    },
  },
};
