
import Vue from "vue";
import statistics from "~/mixins.js/statistics";

Vue.mixin(statistics);

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      taxonomyStatTypes: {
        playerStatTypes: {
          players: [],
        },
        teamStatTypes: {
          team: [],
        },
      },
      statsFilters: {
        seasons: {
          selectedSeason: {
            name: `Scottish Premiership Season 2022`,
            id: `73bf21b6-284c-4d17-9f7f-4068da15d76e`,
          },
        },
        taxonomy: {
          selectedTaxonomy: `players`,
        },
      },
      homeStatsTabs: {
        defaultTabs: [`players`, `team`],
        currentTab: `players`,
      },
    };
  },
  computed: {
    showBlock () {
      return !this.post?.acf?.home_config?.home_stats?.hide_block;
    },
    homeStatsBlock () {
      return this.post?.acf?.home_config?.home_stats;
    },
    block () {
      return this.post?.acf?.home_config?.home_stats?.statistics_config;
    },
    currentTaxonomySlug () {
      return this.taxonomySchema[this.selectedTaxonomy].slug;
    },
    currentTaxonomyProp () {
      return this.taxonomySchema[this.selectedTaxonomy].prop;
    },
    currentTaxonomyStatTypesPath: {
      get () {
        return this.taxonomyStatTypes?.[this.currentTaxonomyProp]?.[
          this.homeStatsTabs.currentTab
        ];
      },
      set (value) {
        this.taxonomyStatTypes[this.currentTaxonomyProp][
          this.homeStatsTabs.currentTab
        ] = value;
      },
    },
    currentTabBlock () {
      if (
        this.homeStatsTabs.currentTab &&
        this.block[this.currentTaxonomySlug]
      ) {
        return this.block[this.currentTaxonomySlug]?.find(
          statGroup =>
            statGroup.display_name.toLowerCase() ===
            this.homeStatsTabs.currentTab,
        );
      }
      return null;
    },
    selectedSeason () {
      return (
        this.defaultSeason?.id || this.statsFilters?.seasons?.selectedSeason?.id
      );
    },
    selectedTaxonomy () {
      return this.statsFilters.taxonomy.selectedTaxonomy;
    },
    competitionName () {
      const competition = this.getDefault(`competition`);
      let years = ``;
      if (this.defaultCalendarPeriod?.displayName) {
        years = this.defaultCalendarPeriod.displayName.replace(`Season `, ``);
      }
      return `${competition?.displayName} ${years}`;
    },
    leaguePositionsAndOriginOfGoalStatTypes () {
      return [...this.teamStatTypes].filter(statType =>
        [`league_positions`, `origin_of_goal`].includes(statType.displayType),
      );
    },
    allOtherStatTypes () {
      return [...this.teamStatTypes].filter(
        statType =>
          ![`league_positions`, `origin_of_goal`].includes(statType.displayType),
      );
    },
  },
  watch: {
    currentTabBlock: {
      immediate: true,
      handler (currentTabBlock) {
        this.allStatTypesInitialDataWithinCurrentTaxonomy =
          this.getAllStatTypesFromBlock({
            taxonomy: this.currentTaxonomySlug,
            block: currentTabBlock,
          });
      },
    },
    allStatTypesInitialDataWithinCurrentTaxonomy: {
      immediate: true,
      async handler (allStatTypesInitialDataWithinCurrentTaxonomy) {
        if (allStatTypesInitialDataWithinCurrentTaxonomy) {
          this.recalibrateCustomChartData();
        }

        if (this.currentTaxonomyStatTypesPath?.length) {
          this.isLoading = false;
          return;
        }
        this.currentTaxonomyStatTypesPath =
          await this.createChartDataForAllStatTypes();
      },
    },
  },
  methods: {
    async getGoalPositionsData ({ selectedSeason, selectedTeamId }) {
      const goalPositions = await this.getStatTypePositions(
        `season/${selectedSeason}/goalpositions/team/${selectedTeamId}`,
        {},
      ).catch(_ => null);
      if (!goalPositions) return null;
      const goalPositionsDataRestructured = {
        goalPositions: Object.values(goalPositions).flat(1),
      };
      return goalPositionsDataRestructured || null;
    },
    appendLeagueAverageToStatTypeData (statTypeData) {
      return [
        ...statTypeData,
        { value: statTypeData[0].league_average, name: `League` },
      ];
    },
  },
};
