
export default {
  props: {
    statType: {
      type: Object,
      required: true,
    },
    title: {
      default: ``,
    },
    subTitle: {
      default: ``,
    },
    graph: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statTypeObject () {
      return this.statType?.results[0].stat_type;
    },
  },
};
