
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    flipCoordinate (goalPosition) {
      // NOTE: this is needed because the (0, 0) origin point (bottom-left-hand corner) from the API data is parallel to that of an SVG (top-left-hand corner)
      return `${100 - goalPosition}%`;
    },
    netPostsOffset (coordinate) {
      // NOTE: this accounts for the net outlines which skew the coordinates (as they are outside of the pitch) on the X-axis
      const offsetCalc = ((22 / 384) * 100) / 2;
      return coordinate < 50
        ? `${coordinate + offsetCalc}%`
        : `${coordinate + -offsetCalc}%`;
    },
  },
};
